/* Forms */
.zoek-form {
    display: none;
    z-index: 5;

    &.zoek-form-weergeven {
        display: inline-block;
    }

    label{
      display: none;
    }

    .field {
      margin: 0;

      .text {
        display: inline-block;
        margin-bottom: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        //border: 1px solid $white;
        border: 0;
        background: transparent;

        &:active{
          outline: 0;
          box-shadow: none;
          //border: 1px solid $white;

          &:focus{
            //border: 1px solid $white;
            outline: 0;
            box-shadow: none;
          }
        }
        &:focus {
          //border: 1px solid $white;
          box-shadow: none;
        }
      }
    }

    &.action {
        width: calc(1.5em + 0.75rem + 2px);
        height: calc(1.5em + 0.75rem + 2px);
        margin-left: 10px;
        text-align: center;
        display: inline-block;
        border-radius: 5px;
        border: none;
        background: $theme-secondary;
        box-shadow: none;
        outline: none;

        &:focus {
            -webkit-box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
        }
    }
}

.itemfilter-form {

  .itemfilter-form--options-wrap {
    @include rem(padding, 15px 20px 5px 20px);
    background: $theme-lighter-gray;
    border-radius: 6px;

    label {
      color: $gray-600;
    }

    select {
      &.dropdown {
        background: $white;
        border: 1px solid darken($theme-lighter-gray, 5%);
        box-shadow: none;
      }
    }
  }
}
/*
@include media-breakpoint-down(md) {
    .zoek-form {
        display: block;
        @include rem(margin-top, 10px);
        position: relative;
        right: 0;
        top: 0;
        transform: none;
        width: 100%;
    }

    .zoek-form .action {
        border-radius: 0 0.25rem 0.25rem 0;
    }

    .zoek-form .field.text {

        .middleColumn .text {
            color: $wit;
            background-color: $theme-medium-dark-gray;
            border: 1px solid $theme-medium-dark-gray;
            border-radius: 0.25rem 0 0 0.25rem;

            &::placeholder {
                color: $wit;
                opacity: 1;
            }

            &:-ms-input-placeholder {
                color: $wit;
            }

            &::-ms-input-placeholder {
                color: $wit;
            }
        }
    }
}
*/
#MemberLoginForm_LoginForm {
  @include rem(max-width, 500px);
}
#MemberLoginForm_LoginForm_action_doLogin {
  @include rem(margin-bottom, 20px);
  @extend .btn;
}
#ForgotPassword {
  float: left;
  clear: both;
  width: 100%;
}
