/* Back to top */
.back-to-top {
  @include transition(color, 0.5s, ease-in-out);
  position: fixed;
  right: 0;
  bottom: 40px;
  line-height: 0;
  cursor: pointer;
  display: block;
  z-index: 99999;
  background-color: #0078c9;
  color: #fff;
  border-radius: 0;
  @include rem(width, 62px);
  @include rem(height, 62px);
  @include rem(line-height, 62px);
  text-align: center;
  &:focus,
  &:visited,
  &:hover {
    color: #fff;
    background-color: #555;
  }
}

@include media-breakpoint-down(md) {
  .back-to-top {
    display: none !important;
  }
}
