.element-imagecontent {

    .element-imagecontent--image {
      @include rem(max-height, 640px);
    }

    .element-imagecontent--button {
        text-transform: uppercase;
        @include rem(font-size, 21px);
        background: $theme-primary;
        border-color: $theme-primary;
        color: $white;
        @include rem(padding, 7.5px 20px);
        i {
            @include rem(padding-right, 7.5px);
        }
    }
}
