.items--results-entry {
    background: $theme-gray;
    @include rem(padding, 30px);
    @include rem(border-radius, 5px);

    &:hover {
        text-decoration: none;
    }

    .items--results-entry-title {
        @include rem(font-size, 24px);
        color: $white;
    }

    .items--results-entry-description {
        background: $wit-dark;
        color: $theme-gray;
        @include rem(border-radius, 5px);
        @include rem(padding, 20px);
        @include rem(font-size, 17px);
        @include rem(min-height, 250px);
    }
}

.items--overview {
  color: $theme-medium-gray;
}

.items--results-pagination {
    @include rem(border-radius, 5px);

    .pagination {
        margin-bottom: 0;
        margin: 1rem 0;
        .label {
            padding: 0.5rem 0.5rem 0.5rem 1.5rem;
            line-height: 1.25;
            font-weight: bold;
        }
        .page-item {
            overflow: hidden;
            &.active .page-link {
                background: $white;
                @include rem(border-radius, 5px);
                font-weight: bold;
            }
            .next,
            .prev {
                margin: 0 1.5rem 0 0;
                &.page-link {
                    @include rem(border-radius, 5px);
                }
            }
            .prev {
                margin-right: 0.5rem;
                &.page-link {
                    @include rem(border-radius, 5px);
                }
                i {
                    @include rem(padding-right, 7.5px);
                    vertical-align: text-bottom;
                }
            }
            .next {
                i {
                    @include rem(padding-left, 7.5px);
                    vertical-align: text-bottom;
                }
            }
        }
        &.--buttons {
            a,
            span {
                border: none;
                color: $theme-gray;
                box-shadow: none;
            }
        }
        &.--pages {

            .page-item {
                a {
                    background: $theme-gray;
                    color: $white;
                    margin: 0 0.5rem;
                    box-shadow: none;
                }
            }

            a,
            span {
                border: none;
                color: $theme-gray;
                @include rem(border-radius, 5px);
            }
        }
    }
}

.category-content {
  color: $wit;
  
  .category-content--title {
    color: $wit;
  }
}



.has-image {
    position: relative;

    .category-content--image {
        background-size: cover;
        background-position: center;
        -webkit-filter: grayscale(0.5);
        filter: grayscale(0.5);
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        &:before {
            content: " ";
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 100%);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        &:after {
            content: " ";
            background: transparentize($theme-medium-gray, .5);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    .breadcrumb-item {
        color: $white;

        &::before {
            color: $white;
        }
        a {
            color: $white;
        }
        &.active {
          color: $white;
        }
    }
}
