/* Page */
.page-background {
    background-color: $wit;
}

.page {
    @include rem(padding, 30px 0 30px 0);
}

.reference--button {
    background a {
        color: #fff;
    }
}

.reference--tekstblock {
    background: #fff;
}

#Form_Zoeken_Trefwoord {
    padding-left: 20px !important;
    background: #666;
}

.btn  {font-size:15px}


.btn#Form_BannerZoeken_action_ZoekenActie  {font-size:16px}


.newsitemrow .element-blocks--item a   {
    color: #131313;
    &:hover  {color:#0078c9; text-decoration: none;}
    &:hover .global-title  {color:#0078c9; text-decoration: none;}
}

#Form_Zoeken_action_ZoekenActie {
    float: left;
}
#Form_Zoeken_Trefwoord {
    color: #fff;
}

a[title="link"] {
    display: inline-block;
    text-indent: -9999px;
    &:before {
        float: left;
        text-indent: 0px;
        content: "Link bekijken \f35d";
        font-family: "Montserrat", 'Font Awesome 5 Free';
    }    
}


a[title="download"] {
    display: inline-block;
    text-indent: -9999px;
    &:before {
        float: left;
        text-indent: 0px;
        content: "Bestand downloaden \f019";
        font-family: "Montserrat", 'Font Awesome 5 Free';
    }    
}
