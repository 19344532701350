.element-usps {

    .element-usps--item {
        @include rem(font-size, 21px);
        color: $wit;

        .global-title {
            color: $wit;
        }

        .fas {
            @include rem(font-size, 24px);
            @include rem(width, 44px);
            color: $theme-secondary;
        }
    }
}
