/* Header */
.header {
    background: $theme-gray;
    z-index: 15;
    transition: background .225s ease-in-out;
    position:relative;

    &.transparent-header-menu {
      background: $theme-gray;
      @include media-breakpoint-down(md) {
        background: $theme-gray;
      }
    }

    @include media-breakpoint-down(md) {
      overflow-y: auto;
    }

    &.background-color {
      background: $theme-gray;
      #Form_Zoeken {
        input::placeholder {
          color: #fff !important;
        }    
      }
    }

    .toggle-zoek-form {
      color: $wit;
      @include media-breakpoint-down(xxl) {
        @include rem(padding, 11px 18px);
      }
      .searchclicked {float:left}
      #Form_Zoeken .image-wrap {float:left}


    }
    .zoeken-wrap {
      position: relative;
    }
    @include media-breakpoint-down(xxl) {
    .header--contact-btn {
        @include rem(padding, 11px 18px);
      }
    }
}
.default-header-menu {
  position:fixed !important;
}
.logo {
    float: left;
    color: #000;

    &:hover {
        text-decoration: none;
        color: #000;
    }
    img {
      @include rem(max-height, 100px);
    }
}

@media print {
    .logo {
        text-decoration: none !important;
    }
}
.zoek-form--searchbar {
  width: 100%;
  .middleColumn .text {
    height: calc(1.5em + 1.5rem + 0px) !important
  }
  .btn.searchclicked {
    display: inline-block;
    @include media-breakpoint-down(lg) {
      margin-left: 0;
    }
    @include media-breakpoint-up(lg) {
      margin-left: 10px;
    }
    @include media-breakpoint-down(md) {
      position: absolute; 
      left: 0;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  fieldset {
    display: inline-block;
    @include media-breakpoint-up(md) {
      width: 80%;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      padding-left: 30px;
    }
    input {
      padding-left: 0 !important;
    }
  }
}
