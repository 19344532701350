.element-blocks {
    &.top-line {
      position: relative;
      &:before{
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        width: 1px;
        transform: translateX(-50%);
        background: $theme-primary;
        @include rem(height, 60px);

        @include media-breakpoint-down(xl) {
          @include rem(height, 45px);
        }

        @include media-breakpoint-down(lg) {
          @include rem(height, 20px);
        }

        @include media-breakpoint-down(md) {
          @include rem(height, 15px);
        }
      }
    }

    .element-blocks--button {
      border: 0;
      background: transparent;
    }

    .element-blocks--link {
      transition: opacity .225s ease-in-out;

      &:hover {
        opacity: 0.9;
        text-decoration: none;
      }
    }

    .element-blocks--title {
      @include rem(font-size, 44px);

      @include media-breakpoint-down(xxl) {
        @include rem(font-size, 34px);
      }

      @include media-breakpoint-down(xl) {
        @include rem(font-size, 30px);
      }

      @include media-breakpoint-down(xl) {
        @include rem(font-size, 38px);
      }

      @include media-breakpoint-down(lg) {
        @include rem(font-size, 20px);
      }

    }

    .element-blocks--item-video-button {
      position: relative;
      i.fab.fa-youtube{
        position:absolute;
        left:40%;
        top:40%;
       

        &:before {
          content: "\f167";
          font-size: 60px;
          }
      }
      
    }

    .element-blocks--item {
        background: $wit;
        height: 100%;
        position: relative;
        z-index: 5;

        .blok-video-iframe  {
          border: 0;
        }

        &:before {
          content: '';
          bottom: 0;
          //box-shadow: 0 4px 12px rgba(0,0,0,0.5);
          box-shadow: 0 10px 25px transparentize($theme-medium-gray-lighter, 0.65);
          filter: blur(6px);
          height: 94%;
          left: 3%;
          position: absolute;
          width: 94%;
          z-index: -1;
        }

        .element-blocks--image {
            img {
              width: 100%;
            }
        }
        .global-title {
            @include rem(font-size, 20px);
            margin-bottom: 0;
            word-break: break-word;

            @include media-breakpoint-down(xl) {
              @include rem(font-size, 24px);
            }

            @include media-breakpoint-down(xl) {
              @include rem(font-size, 21px);
            }
            
        }

        .element-blocks--content {
            background: $wit;
            @include rem(padding, 0px 30px);
        }
    }
}
