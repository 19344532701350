/* Breadcrumbs */
.breadcrumb {
  background-color: transparent;
  padding: 0;
  @include rem(margin-bottom, 20px);
  color: $theme-medium-gray;
  @include rem(font-size, 17px);
}

.breadcrumb-item {
  color: $theme-medium-gray;
  @include rem(font-size, 17px);

  a {
    color: $theme-primary;
    @include rem(font-size, 17px);
  }

  &.active {
    color: $theme-medium-gray;
  }

  + .breadcrumb-item::before{
    content: "\203A";
    color: $theme-medium-gray-lighter;
    @include rem(font-size, 21px);
    @include rem(margin-left, 6px);
    @include rem(margin-right, 6px);
    line-height: 1;
  }
}
