/* Footer */
.footer {
    position: relative;
    background-color: $theme-medium-gray;
    color: $wit;

    .footer-bg {
      position: relative;
      overflow: hidden;

        &:before {
          content: '';
          background-image: url('../img/footer_bg.png');
          background-repeat: no-repeat;
          background-size: contain;
          z-index: 5;
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: 0;
          display: block;
          top: 0;
          opacity: .4;
          left: 0;
          @include media-breakpoint-down(md) {
            background-image: none;
        }
      }
    }
    .footer--title.global-title {
      @include media-breakpoint-up(lg) {
        pointer-events: none;
        &:hover {
          color: $wit;
        }
      }
    }
    .collapse{
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &:before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background: linear-gradient(to left, rgba(0, 0, 0, 0) 85%, rgba(0, 0, 0, .5) 100%);
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .footer--link,
    .footer--title {
        color: $wit;
        text-decoration: none;
        @include rem(font-size, 16px);

        &:hover{
          color: $theme-primary;
        }
        i {
          float: right;
        }
    }

    .footer--link{
      @include media-breakpoint-down(md) {
        @include rem(font-size, 14px);
      }
    }

    .footer--social-icon {
        @include rem(font-size, 32px);
    }
    .footer--icon {
        @include rem(font-size, 13px);
    }
    .list-inline-item:not(:last-child) {
        @include rem(margin-right, 15px);
    }
    .link-list {
        .list-inline-item {
            display: block;
        }
    }
    .google-maps {
        min-height: 350px;
        height: 100%;
    }
}

.bottom-footer {
    background-color: $theme-medium-gray;
    color: $gray-300;
    font-weight: 500;
    border-top:1px solid lighten($theme-medium-gray, 5%);

    .bottom-footer--link {
        color: $wit;
        text-decoration: none;

        &:hover{
          color: $theme-primary;
        }
    }

    .list-inline-item {
        &.footer-logo {
          &:after {
            content: '';
            display: none;
          }
        }
        &:not(:last-child):after {
            content: '/';
            @include rem(margin-left, 5px);
            opacity: 0.4;
        }
    }
}
