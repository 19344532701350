.element-balk {
  background: $theme-primary;
  color: $wit;

  .element-balk--title {
    color: $wit;
  }

  .element-balk--content-bold {
    @include rem(font-size, 24px);
    line-height: 1.5;

    @include media-breakpoint-down(md) {
      @include rem(font-size, 21px);
      @include rem(margin-bottom, 10px);
    }
  }
}
