.element-item-blocks {

  .element-item-blocks--image {
    background: $wit;
    box-shadow: 0 10px 25px transparentize($theme-medium-gray-lighter, 0.8);
  }

  .element-item-blocks--content-title {
    @include rem(font-size, 24px);
    letter-spacing: 0.5px;
    @include media-breakpoint-down(xxxl) {
      @include rem(font-size, 21px);
    }
    @include media-breakpoint-down(lg) {
      @include rem(font-size, 21px);
    }
  }

  .element-item-blocks--link {
    color: $theme-gray;
  }
}


span.nav--catslider  {

margin-left:15px; background:#ffff; padding:20px; -webkit-box-shadow: 0px 9px 10px 0px rgba(0,0,0,0.1);
-moz-box-shadow: 0px 9px 10px 0px rgba(0,0,0,0.1);
box-shadow: 0px 9px 10px 0px rgba(0,0,0,0.1);

span.divider{

color:#999999;
font-size: 20px;

}

}
@include media-breakpoint-up(xxl) {
  .swiper-slide {
    width: 23% !important;
  }
}