.element-contactbalk {
  background: $theme-gray;
  color: $wit;
  @include rem(padding-top, 45px);
  @include rem(padding-bottom, 45px);

  @include media-breakpoint-down(xl) {
    @include rem(padding-top, 45px);
    @include rem(padding-bottom, 45px);
  }

  @include media-breakpoint-down(lg) {
    @include rem(padding-top, 45px);
    @include rem(padding-bottom, 45px);
  }

  @include media-breakpoint-down(md) {
    @include rem(padding-top, 30px);
    @include rem(padding-bottom, 30px);
  }

  .element-contactbalk-slogan {
    @include rem(font-size, 21px);
    font-style: italic;
    @include media-breakpoint-down(md) {
      @include rem(font-size, 16px);
    }
  }
}
