* {
    box-sizing: border-box;
}

html {
    background-color: $wit;
}

body {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-display: fallback;
    font-weight: 500;
    height: 100%;
    color: $theme-gray;
    @include rem(font-size, 16px);
    line-height: 1.8;
    text-rendering: optimizeLegibility;
    @include media-breakpoint-down(md) {
      @include rem(font-size, 14px);
    }
}

img {
    max-width: 100%;
}

strong {
    font-weight: 600;
}

button {
  background: transparent;
}

.nav-tabs {
  button {
    outline: 0;

    &:active,
    &:hover,
    &:focus {
      outline: 0;
    }
  }
}

.gallery-video {
  .element-blocks--image{
    @include media-breakpoint-down(md) {
      img{
        @include rem(max-height, 340px);
        object-fit: cover!important;
      }
    }
  }
}


.element-item-blocks--image {
  @include media-breakpoint-down(md) {
    img{
      @include rem(max-height, 340px);
    }
  }
}

.btn-theme-dark {
  color: $theme-secondary;
  &:hover {
    color: $theme-secondary;

    &:focus,
    &:active {
      color: $theme-secondary;

      &:focus{
        color: $theme-secondary;
      }
    }
  }
}

.light-background {
  background-color: $wit-dark;
}

.btn-primary {
  color: $wit;
  &:hover {
    color: $wit;

    &:focus,
    &:active {
      color: $wit;

      &:focus{
        color: $wit;
      }
    }
  }
}

.google-maps {
    @include rem(padding, 30px);
    overflow: hidden;
    border: 0;

    @include media-breakpoint-down(md) {
        padding: 0;
    }
}

.collapsing {
    -webkit-transition: height 0.3s ease;
    -moz-transition: height 0.3s ease;
    -o-transition: height 0.3s ease;
    transition: height 0.3s ease;
}

.action {
    cursor: pointer;
}

.slick-slide {
    outline: 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.p-block-small {
    @include rem(padding, 15px 0);
}


.p-block-medium {
    @include rem(padding, 30px 0);

    @include media-breakpoint-down(lg) {
        @include rem(padding, 15px 0);
    }
}

.p-block {
    @include rem(padding, 45px 0);

    @include media-breakpoint-down(lg) {
        @include rem(padding, 30px 0);
    }

    @include media-breakpoint-down(md) {
        @include rem(padding, 15px 0);
    }
}

.sfeer-bg {
    background-size: cover;
    background-position: center right;
    position: relative;

    &:before {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        background: linear-gradient(to left, rgba(23, 23, 27, 0) 0%, rgba(23, 23, 27, 1) 75%);
    }
}

.image-wrap {
    &.gradient {
        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50%;
            content: "";
            background: linear-gradient(to bottom, rgba(64, 67, 84, 0) 0%, rgba(64, 67, 84, 0.65) 100%);
        }
    }
}


@media only screen and (max-width: 768px) {
  breadcrumbs  {display:none}
}


h2.footer--title {appearance: none !important;}

// Kleuren bericht verplicht
.message{
  background-color: #f8d7da;
    padding: 5px;
    border: darkred 1px solid;
    border-radius: 5px;
    color: #721c24;
}
// melding privacy checkbox
#Form_ContactFormulier_PrivacyCheckbox_Holder .message{
  position: relative;
  top: 30px;
  float: left;
}
// mobiel responsive
@media only screen and (max-width: 600px) {
  #Form_ContactFormulier_PrivacyCheckbox_Holder .message {
    position: relative;
    top: -42px;
    float: left;
  }
}

// Privacy text positie
.element-contactform .checkbox-holder p {
  position: absolute;
  left: 35px;
}

.column-background {
  background:rgba(0,0,0, 0.5);
  padding:50px 0px;
}
#e54 {
  p {
    a {
      background:#0f80cc;
      border:1px solid #0f80cc;
      padding:5px 25px;
      color:#FFF;
      transition:all 0.5s ease;

      &:hover {
        text-decoration:none;
        border:1px solid #0f80cc;
        color:#0f80cc;
        background:transparent;
      }
    }
  }

}
