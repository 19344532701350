/* Variables */

// Fonts
$font-family-body: "Arial", sans-serif;
$font-family-headings: "Arial", sans-serif;

$theme-primary: #0078c9;
$theme-secondary: #1d1d1b;

//Standaard kleuren
$theme-medium-gray-lighter: #a4a4a4;
//$theme-medium-gray: #999999;
$theme-medium-gray: #232322;
$theme-medium-dark-gray: #3a3a3f;
//$theme-gray: #171716;
$theme-gray: #131313;
$theme-gray-dark: #1c1c1f;
$wit: #fff;
$wit-dark: #f9f9f9;
$theme-light-gray: #f7fffe;
$theme-lighter-gray: #f4f4f4;

// Colours - Text & links
$colour-text: #1c1d19;
$colour-link: #337ab7;
$colour-link-hover: #23527c;

// Colours - Breadcrumbs
$colour-breadcrumb-text: $colour-text;
$colour-breadcrumb-link: $colour-link;
$colour-breadcrumb-link-hover: $colour-link-hover;
$colour-breadcrumb-link-section: $colour-link;
$colour-breadcrumb-link-section-hover: $colour-link-hover;
$colour-breadcrumb-divider: #ddd;

// Colours - Sidebar
$colour-sidebar-link: #212529;
$colour-sidebar-link-hover: #212529;
$colour-sidebar-link-section: $colour-link;
$colour-sidebar-link-section-hover: $colour-link-hover;
$colour-sidebar-link-current: #212529;
$colour-sidebar-link-current-hover: #212529;
