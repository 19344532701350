.element-contactform {
    background-repeat: repeat-x;

    .element-contactform--formarea {
      background: $white;
    }
    .element-contactform--adressen {
      a{
        color: $theme-secondary;
      }
    }

    .element-content--title {
      @include rem(font-size, 28px);
    }

    .field.honeypotspamprotector {
        margin-bottom: 0;
    }
    .text,
    .numeric,
    .email,
    .textarea {
        input,
        textarea {
            border: solid 1px darken($theme-lighter-gray,5%);
            @include rem(padding, 20px 15px);
            &:focus {
                box-shadow: none;
            }
        }
    }
    .field.textarea {
        height: calc(100% - 1rem);
        .middleColumn {
            height: 100%;
            textarea.textarea {
                height: 100%;
            }
        }
    }
    .optionset li {
        display: inline-block;
        position: relative;
        @include rem(margin-right, 40px);
        .radio {
            @include rem(width, 25px);
            @include rem(height, 25px);
            visibility: hidden;
            &:checked ~ label:before {
                content: "\f111";
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                @include rem(line-height, 21px);
                @include rem(font-size, 17px);
                text-align: center;
                color: $theme-primary;
            }
        }
        label {
            vertical-align: super;
            display: inline-block;
            cursor: pointer;
            @include rem(font-size, 18px);
            &:before {
                position: absolute;
                @include rem(width, 25px);
                @include rem(height, 25px);
                content: " ";
                background: $white;
                border: solid 2px $theme-medium-gray-lighter;
                left: 0;
                top: 0;
                @include rem(border-radius, 25px);
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .checkbox-holder {
        color: $theme-medium-gray;
        p {
            @include rem(line-height, 25px);
            display: inline-block;
        }
        a {
            color: $theme-medium-gray;
            text-decoration: underline;
        }
        display: block;
        clear: both;
        position: relative;
        input {
            @include rem(margin-right, 10px);
            @include rem(width, 25px);
            @include rem(height, 25px);
            visibility: hidden;
            &:checked ~ label:after {
                content: "\f00c";
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                line-height: 0;
                position: absolute;
                @include rem(font-size, 18px);
                @include rem(top, 9px);
                @include rem(left, 1px);
                color: $theme-medium-gray;
            }
        }
        label {
            position: absolute;
            top: 0;
            left: 0;
            @include rem(width, 25px);
            @include rem(height, 25px);
            background: $wit;
            border: solid 1px darken($theme-lighter-gray,5%);
            cursor: pointer;
        }
    }
    .action.btn {
        text-transform: uppercase;
        background: $theme-primary;
        border-color: $theme-primary;
        color: $white;
        @include rem(padding, 7.5px 20px);
        i {
            @include rem(padding-right, 7.5px);
        }
    }
}
