.banner-zoek-form {
  label{
    display: none;
  }

  .field {
    &.text {
      margin-bottom: 0;
    }
  }

  #Form_Zoeken_Trefwoord_Holder {
    .middleColumn {
      position: relative;

      .text {
        @include rem(padding-left, 45px);
      }

      &:before {
        content: "\f002";
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color: $gray-600;
        margin-left: 10px;
        position: absolute;
        z-index: 5;
        top: 50%;
        transform: translateY(-50%);
        @include rem(left, 5px);
      }
    }
  }

}

.element-video-banner--line {
  position: relative;

  &:after {
    content: '';
    height: 50%;
    width: 1px;
    background: $theme-primary;
    position: absolute;
    z-index: 5;
    bottom: 0;
    transform: translateY(40%);

    @include rem(left, 45px);

    @include media-breakpoint-down(xl) {
      @include rem(left, 30px);
    }

    @include media-breakpoint-down(lg) {
      @include rem(left, 20px);
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.element-video-banner {
  z-index: 0;
  position: relative;
  overflow: hidden;
  height: 100vh;


  .element-video-banner--zoekform {
    background: $theme-gray;

    .btn-primary {
      color: $wit;
    }
    .input-group {
      position: relative;

      .fa {
        color: $gray-600;
        margin-left: 10px;
        position: absolute;
        z-index: 5;
        top: 50%;
        transform: translateY(-50%);
        @include rem(left, 15px);
      }

      .form-control {
        @include rem(padding-left, 45px);
      }
    }
  }

  .element-video-banner--content {
    &:before {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        background: linear-gradient(to left, rgba(23, 23, 27, 0) 0%, rgba(23, 23, 27, .25) 75%);
    }
  }

  .element-video-banner--toptitle {
    color: $wit;
    text-shadow: 0 2px 1px rgba(23, 23, 27, .25);
    @include rem(font-size, 18px);
  }

  .element-video-banner--text {
    color: $wit;
    text-shadow: 0 2px 1px rgba(23, 23, 27, .25);
  }

  .element-video-banner--title {
    color: $wit;
    text-shadow: 0 2px 1px rgba(23, 23, 27, .25);
    @include rem(font-size, 62px);
    line-height: 1;


    @include media-breakpoint-down(xxl) {
      @include rem(font-size, 52px);
    }

    @include media-breakpoint-down(xl) {
      @include rem(font-size, 42px);
    }

    @include media-breakpoint-down(xl) {
      @include rem(font-size, 38px);
    }
    @include media-breakpoint-down(lg) {
      @include rem(font-size, 32px);
    }
  }

  .element-video-html {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  }

  .poster-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $wit;
  }

  .element-video-banner--content {
    position: relative;
    z-index: 5;
  }
}
