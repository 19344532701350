.element-banner {

    .banner--slider {
      height: 70vh;
    }

    .element-banner--title {
        font-family: $font-family-headings;
        color: $white;
        line-height: 1.1;
        @include rem(font-size, 62px);
        font-weight: 700;
        text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
    }

    .element-banner--content {
        background: linear-gradient(120deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .element-banner--item {
        height: 100%;
        background-size: cover;
        background-position: center;
        -webkit-filter: grayscale(0.5);
        filter: grayscale(0.5);
    }

    .slide-next {
        @include rem(font-size, 48px);
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 15px;
        z-index: 9999;
        color: $theme-light-gray;
        outline: none;
        box-shadow: none;

        @include media-breakpoint-down(md) {
          @include rem(font-size, 28px);
          border-radius: 0;
          right: 0;
          background: rgba(0,0,0,0.5);
        }
    }
    .slide-prev {
        @include rem(font-size, 48px);
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        left: 15px;
        z-index: 9999;
        color: $theme-light-gray;
        outline: none;
        box-shadow: none;

        @include media-breakpoint-down(md) {
          @include rem(font-size, 28px);
          border-radius: 0;
          left: 0;
          background: rgba(0,0,0,0.5);
        }
    }
}
input#Form_BannerZoeken_Trefwoord {
  background-image: url("data:image/svg+xml,%3Csvg class='search-icon' width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.8779 18.6996L14.0681 12.8897C15.17 11.5293 15.8334 9.79975 15.8334 7.9167C15.8334 3.55145 12.2819 0 7.91666 0C3.55141 0 0 3.55145 0 7.9167C0 12.2819 3.55145 15.8334 7.9167 15.8334C9.79975 15.8334 11.5293 15.17 12.8897 14.0681L18.6996 19.878C18.8623 20.0407 19.1261 20.0407 19.2889 19.878L19.878 19.2888C20.0407 19.1261 20.0407 18.8623 19.8779 18.6996ZM7.9167 14.1667C4.47024 14.1667 1.66668 11.3631 1.66668 7.9167C1.66668 4.47024 4.47024 1.66668 7.9167 1.66668C11.3631 1.66668 14.1667 4.47024 14.1667 7.9167C14.1667 11.3631 11.3631 14.1667 7.9167 14.1667Z' fill='gray'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 15px;
  padding-left: 50px;


 
}
input#Form_BannerZoeken_Trefwoord::placeholder {
  color: #999 !important;
}