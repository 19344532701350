/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
    @include rem(font-size, 38px);
    font-family: $font-family-headings;
    color: $theme-gray;
    line-height: 1.3;
    text-transform: uppercase;
    font-weight: 700;

    @include media-breakpoint-down(xxl) {
        line-height: 42px;
    }

    @include media-breakpoint-down(xl) {
        line-height: 38px;
    }

    @include media-breakpoint-down(xl) {
      line-height: 36px;
    }

    .regular-title {
        @include rem(font-size, 38px);
        font-weight: 500;

        @include media-breakpoint-down(xl) {
            @include rem(font-size, 38px);
        }

        @include media-breakpoint-down(md) {
            @include rem(font-size, 32px);
        }
    }
}

.content-area {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include rem(font-size, 32px);
    line-height: 40px;
  }
}

.global-title {
    @include rem(font-size, 38px);
    font-family: $font-family-headings;
    color: $theme-gray;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.3;

    @include media-breakpoint-down(xxl) {
      @include rem(font-size, 34px);
    }

    @include media-breakpoint-down(xl) {
      @include rem(font-size, 30px);
    }

    @include media-breakpoint-down(xl) {
      @include rem(font-size, 28px);
    }
    @include media-breakpoint-down(lg) {
        @include rem(font-size, 20px);
    }
}

/*
h1 {
    @include rem(font-size, 32px);
}

h2 {
    @include rem(font-size, 28px);
}

h3 {
    @include rem(font-size, 24px);
}

h4 {
    @include rem(font-size, 21px);
}

h5 {
    @include rem(font-size, 18px);
}

h6 {
    @include rem(font-size, 16px);
}
*/
